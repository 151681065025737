.composition {
  padding: 3rem 0 3rem 0;
  margin-bottom: 4rem;
  background: #333333;
  padding: 2rem;

  &__title {
    font-size: 3rem;
    line-height: 2.8rem;
    font-weight: $light;
    display: block;
    color: white;
    margin-bottom: 3rem;
  }

  &__notes {
    display: block;
    margin-bottom: 4rem;

    p {
      color: white;
      font-weight: $light;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  &__show {
    border: 1px solid #ffffff;
    font-size: 2rem;
    background: transparent;
    color: white;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    margin-bottom: 2rem;

    &:hover {
      background: white;
      color: #000000;
    }
  }

  &__audio-label {
    color: #c8003d;
    font-size: 16px;
    display: inline-block;
    margin-right: 10px;
  }

  &__play {
    background: transparent;
    border: none;
    color: #c8003d;
    padding: 0;
    text-align: center;
    cursor: pointer;
    margin-bottom: 2rem;

    i {
      margin: 0;
    }
  }

  &__youtube {
    width: 90%;
    margin: 0 0 4rem 0;

    &-frame {
      margin: 0 0 1rem 0;
      background: black !important;

      iframe {
        width: 100%;
        height: 22vw;
        max-height: 300px;
        background: black !important;
      }
    }
  }

  &__video-file {
    margin-bottom: 2rem;
    a {
      font-size: 1.6rem;
      line-height: 2rem;
      color: #c8003d;
    }

    .external {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  &__sample {
    a {
      font-size: 1.6rem;
      line-height: 2rem;
      color: #c8003d;
    }

    i {
      display: inline-block;
      margin-left: 1rem;
    }
  }
}

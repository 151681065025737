html {
  font-size: 62.5%;
}

*, html, body {
  margin: 0;
  padding: 0;
  font-family: $lato;
  box-sizing: border-box;
}


body {
 overflow-x: hidden;
 height: 100vh;
 width: 100vw;
 max-height: 100%;
 max-width: 100%;
 background: black;
 &.isMenuOpened {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
 }
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10rem;
}

.wrapper {
  padding: 10rem 11rem 0 12rem;

}

.root {
  height: 100%;
}

@include tablet {
  .wrapper {
    width: 90%;
    padding: 6rem 0 0 3rem;
  }
  .container {
    padding: 0 2rem;
  }
}
@include mobile {
  .wrapper {
    width: 85%;
  }
}
.works {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;

  &__inner {
    position: relative;
    min-height: 100vh;
  }
  &__content {
    padding: 0 0 5rem 0;
  }

  &__menu {
    margin-bottom: 6rem;
    list-style: none;
    background: transparent;

    &-item {
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: $light;
      color: white;
      cursor: pointer;
      margin-bottom: 1rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 1400px;
  }
}

@include hd {
  .works {
    &__list {
      max-width: 1400px;
    }
  }
}

.work {
  width: 100%;

  &__right {
    width: 100%;
    max-width: 800px;
  }

  &__image {
    max-width: 800px;
    width: 90%;
    height: auto;
  }

  &__title {
    font-size: 3rem;
    line-height: 3.5rem;
    display: inline-block;
    color: white;
    font-weight: $regular;
    margin-bottom: 0.5rem;
    padding: 0.6rem 0;
    text-transform: uppercase;
  }

  &__text {
    color: white;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: $light;
    margin-bottom: 2rem;
  }

  &__source {
    color: #c8003d;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: $light;
  }
}

@include desktop {
  .works {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;

    &__inner {
      position: relative;
      min-height: 100vh;
    }

    &__list {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 700px;
    }
  }

  .work {
    width: 100%;
    margin: 0 0 2rem 0;
  }
}

@include tablet {
  .work {
    margin: 0 0 0 0;
    flex-direction: column;
    gap: 10px;
  }
}

.calendar {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;

  &__inner {
    position: relative;
    min-height: 100vh;
  }

  &__content {
    padding: 0 0 5rem 0;
  }

  &__list {
    max-width: 600px;
  }

  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
    object-position: 30vw -12vw;
    //transform: scale(0.9);
  }
}

.cal {
  width: 100%;
  margin: 0 0 8rem 0;
  padding: 0 0 2rem 0;
  margin: 0 0 4rem 0;
  border-bottom: 1px solid darken($gray, 20%);

  &:last-child {
    margin: 0;
    padding: 0;
    border-bottom: none;
  }

  &__title {
    font-size: 2rem;
    line-height: 3rem;
    color: white;
    font-weight: $bold;
    color: white;
    margin: 0 0 1rem 0;
    font-style: italic;
  }

  &__date {
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: lighten($gray, 20%);
    font-weight: $regular;
    margin: 0 0 1rem 0;
    display: flex;

    i {
      margin: 0.5rem 1rem 0 0;
      color: darken($gray, 10%);
    }
  }

  &__address {
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: lighten($gray, 20%);
    font-weight: $regular;
    margin: 0 0 1rem 0;
    display: flex;

    > i {
      margin: 0.5rem 1rem 0 0;
      color: darken($gray, 10%);
    }

    a {
      margin: 0 0 0 5px;
      display: inline-block;
      i {
        color: $red;
        margin: 0 0 0 5px;
      }
    }
  }

  &__short {
    display: flex;
    i {
      margin: 0.2rem 0.8rem 0 0;
      color: white;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: darken($gray, 10%);
    }
  }

  &__short-info {
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: white;
    font-weight: $light;
    color: $gray;
    margin: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
  }

  &__long {
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding: 0 0 0 2.5rem;
    color: white;
    font-weight: $light;
    color: $gray;
    margin: 0;
    //opacity: 0;
    max-height: 0;
    overflow: hidden;
    // visibility: hidden;
    transition: max-height 0.2s ease;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    &.expanded {
      //opacity: 1;
      margin: 0 0 1rem 0;
      max-height: 1000px;
      //visibility: visible;
      transition: max-height 0.5s ease;
    }
  }
  &__more {
    color: white;
    font-size: 1.6rem;
    font-weight: $regular;
    display: block;
    margin: 0 0 0 2.3rem;
    padding: 0 2rem 0 0;
    position: relative;
    display: inline-block;
    cursor: pointer;

    svg {
      width: 10px;
      fill: white;
      position: absolute;
      right: 0;
      top: 2px;
    }

    &.up {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

@include desktop {
  .calendar {
    &__back {
      display: none;
    }
  }
}

@include mobile {
  .cal {
    &__title {
      font-size: 2rem;
      line-height: 3rem;
    }
    
    &__address {
      a {
        span {
          display: none;
        }
      }
    }
  }
}

$lato: 'Lato', sans-serif;

$hd-width: 1880px;
$desktop-width: 1200px;
$tablet-width: 768px;
$mobile-width: 500px;


$gray: #B3B3B3;
$gray-lightest: #f7f7f7;
$gray-dark: #2a2a2a;
$red: #FF2F6E ;



$light: 300;
$regular: 400;
$medium: 500;
$bold: 600;
$extra: 700;
$super: 800;
$black: 900;

.listen {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  &__inner {
    position: relative;
    min-height: 100vh;
  }
  &__content {
    padding: 0 0 5rem 0;

  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1400px;
  }

}

@include hd {
  .listen {
    &__list {
      max-width: 1400px;
    }
  }
  .audio {
    width: 30%;
  }
}

.audio {
  width: 30%;
  margin: 0 0 4rem 0;
  &__frame {
    margin: 0 0 1rem 0;
    background: black !important;
    iframe {
      width: 100%;
      height: 600px;
      background: black !important;
    }
  }
  &__title {
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: white;
    font-weight: $light;
  }

}

@include desktop {
  .listen {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    &__inner {
      position: relative;
      min-height: 100vh;
    }
    &__content {

    }
    &__list {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 390px;
    }
  }
  .audio {
    width: 100%;
    margin: 0 0 2rem 0;
    &__frame {
      iframe {
        height: 500px;
      }
    }
  }
}

@include tablet {
  .listen {
    &__list {
      max-width: 300px;
    }
  }
  .audio {
    margin: 0 0 4rem 0;
    &__frame {
      iframe {
        width: auto;
        height: 450px;
      }
    }
  }
}

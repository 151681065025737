.about {
  width: 100vw;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right -35vw;
  position: relative;

  &__inner {
    position: relative;
    min-height: 100vh;
  }

  &__content {
    padding: 0 0 1rem 0;
  }

  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
    object-position: 30vw 0;
  }

  &__text {
    width: 50%;
    min-width: 500px;
    max-width: 700px;

    p:nth-child(n + 4) {
      display: none;
    }
  }

  &__text--enhanced {
    p:nth-child(n + 4) {
      display: block;
    }
  }

  .more {
    border: 1px solid #ffffff;
    background: transparent;
    color: white;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    margin-bottom: 2rem;

    &:hover {
      background: white;
      color: #000000;
    }
  }
}

@include tablet {
  .about {
    &__text {
      min-width: 100%;
      width: 100%;
    }

    &__back {
      display: none;
    }
  }
}

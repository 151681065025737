.fade-in{
  opacity: 0;
  &-active {
    animation: fadein 0.4s forwards;
    animation-timing-function: ease-in;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

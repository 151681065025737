.arrows-top {
  background: transparent;
  position: absolute;
  top: 4rem;
  width: 100%;
  padding: 4rem 0;

  &__inner {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 6rem);
    margin: 0 auto;
  }

  &__arrow {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin: 0 0 0 1rem;
      fill: white;
      width: 3rem;
      transition: transform 0.3s ease;
    }

    &.light {
      svg {
        fill: black !important;
      }
    }

    &-left {
      svg {
        margin: 0 1rem 0 0;
      }

      &:hover {
        svg {
          transform: translateX(-7px) !important;
          transition: transform 0.3s ease !important;
        }
      }
    }

    &:hover {
      svg {
        transform: translateX(7px);
        transition: transform 0.3s ease;
      }
    }
  }
}

@include tablet {
  .arrows-top {
    &__inner {
      width: calc(100% - 4rem);
    }
    &__arrow {
      span {
        display: none;
      }
      svg {
        width: 3rem;
      }
    }
  }
}

.button-arrow-black {
  background: black;
  color: white;
  display: block;
  font-size: 1.6rem;
  font-weight: $light;
  padding: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  z-index: 0;
  border: none;
  
  img {
    width: 20px;
    margin: 0 0 0 1rem;
  }
}

.start{
    position: fixed;
    top: 1.5rem;
    right: 2rem;
    z-index: 10;
  }

@include tablet {
  .start {
    display: none;
  }
}
.sierra {
  width: 100vw;
  max-width: 100%;

  &__content {
    padding: 0 0 1rem 0;
  }

  &__block {
    display: flex;
    gap: 20px;
  }

  &__text {
  }
}

@include tablet {
  .sierra {
    &__block {
      flex-direction: column;
      gap: 20px;
    }
  }
}

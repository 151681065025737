.home {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top 18% right 60%;

  &__review {
    position: absolute;
    top: 55%;
    right: 10%;
    width: 100%;
    max-width: 350px;
    animation: appear 3s ease;
    text-align: right;

    p {
      color: lighten($gray, 30%);
      font-size: 1.8rem;
      line-height: 2.5rem;
      font-weight: $light;
      margin: 0 0 2rem 0;
      text-align: right;

      &:last-child {
        margin: 0;
      }
    }

    h3 {
      color: $red;
      font-size: 1.6rem;
      line-height: 3.2rem;
      font-weight: $light;
      text-align: right;
    }
  }
  &__image {
    position: fixed;
    width: 80vw;
    height: 100vw;
    left: 50%;
    transform: translateX(-50%);
    top: 10vw;
    img {
      display: none;
    }
  }
}

@include hd {
  .home {
    &__review {
      right: 20%;
      max-width: 400px;
      p {
        font-size: 2rem;
        line-height: 3rem;
      }
      h3 {
        font-size: 1.8rem;
        line-height: 3rem;
      }
    }
  }
}

@include desktop {
  .home {
    &__review {
      right: 8%;
      top: 55%;
      transform: translateY(-50%);
      max-width: 350px;
    }
    &__image {
      top: 15vw;
    }
  }
}

@include tablet {
  .home {
    &__review {
      display: none;
    }
    &__image {
      top: 25vw;
      width: 90vw;
      height: 100vw;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 100px;
  z-index: 100;
  &__dot {
    width: 3rem;
    height: 3rem;
    background: darken($red, 20%);
    border-radius: 50%;
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s ease;
    }
  }

}

@include mobile {
  .footer {
    &__dot {
      width: 2rem;
      height: 2rem;
      right: 2rem;
      bottom: 2rem;
    }
  }
}
